import { Box, Button, Card, CardContent, Grid, InputLabel, TextField, Typography } from '@mui/material';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getGraphClient } from 'csam/admin/pages/phishing-emails/getGraphClient';
import React, { useContext, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { useMsal } from '@azure/msal-react';
import EmailInputWithTags from './EmailInputWithTags';
import { useAuthenticatedQuery } from 'csam/api/api';
import { fixed, getImage } from 'csam/utils/Constants';
import LocaleContext from 'csam/components/LocaleContext';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminInternalError from '../AdminInternalError';
import { toast } from 'react-toastify';

interface EmailState {
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  additionalData: Object | { [key: string]: string }[];
}

interface EmailTemplateData {
  id: number;
  template_title: string;
  email_subject: string;
  email_body: string;
  created_at: string;
  status: number;
}

interface TemplateResponse {
  success: boolean;
  data: EmailTemplateData;
}

const ComposeEmail = () => {
  const { instance, accounts } = useMsal();
  const [open, setOpen] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const { locale } = useContext(LocaleContext);

  const primaryEmail = 'Security_Compliance_Awareness@ab-inbev.com';
  const secondaryEmail = 'GISP_Support@ab-inbev.com';

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<TemplateResponse[]>([locale], {
    url: `${fixed}admin/${locale || localeFromStorage}/email_templates`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const handleTemplateSelect = (templateId: string) => {
    setSelectedTemplate(templateId);
  };

  const [fromEmails, setFromEmails] = useState<string[]>([primaryEmail]);
  const [email, setEmail] = useState<EmailState>({
    to: [],
    cc: [],
    bcc: [],
    subject: '',
    body: '',
    additionalData: [],
  });

  const [_emailInput, setEmailInput] = useState('');

  const handleUploadExcel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (event) => {
      const csv = event.target?.result;
      if (typeof csv !== 'string') return;

      const lines = csv
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line.length > 0);
      console.log('lines', lines);

      const headers = lines[0]?.split(',').map((header) => header.trim().replace(/\r/g, '').toLowerCase()) || [];
      console.log('check for headers:', headers);

      let emailColumnIndex = -1;
      let ccColumnIndex = -1;
      let bccColumnIndex = -1;
      let additionalColumns: { [key: string]: number } = {};

      for (let i = 0; i < headers.length; i++) {
        const header = headers[i].trim();
        console.log(
          `Header ${i}: "${header}" (Bytes: ${Array.from(header)
            .map((c) => c.charCodeAt(0))
            .join(',')})`,
        );

        if (header === 'email') {
          emailColumnIndex = i;
        } else if (header === 'cc') {
          ccColumnIndex = i;
        } else if (header === 'bcc') {
          bccColumnIndex = i;
        } else {
          additionalColumns[header] = i;
        }
      }

      console.log('emailColumnIndex', emailColumnIndex);
      console.log('ccColumnIndex', ccColumnIndex);
      console.log('bccColumnIndex', bccColumnIndex);
      console.log('additionalColumns', additionalColumns);

      if (emailColumnIndex === -1) {
        alert('No email column found');
        return;
      }

      const emails: { to: string; cc: string[]; bcc: string[]; additionalData: { [key: string]: string } }[] = [];
      for (let i = 1; i < lines.length; i++) {
        const columns = lines[i].split(',').map((col) => col.trim().replace(/\r/g, ''));
        console.log('columns', columns);

        const email = columns[emailColumnIndex];

        const cc =
          ccColumnIndex !== -1
            ? columns[ccColumnIndex]
                ?.split(/[ ,;]/)
                .map((cc) => cc.trim().replace(/["]/g, ''))
                .filter(Boolean)
            : [];
        const bcc =
          bccColumnIndex !== -1
            ? columns[bccColumnIndex]
                ?.split(/[ ,;]/)
                .map((bcc) => bcc.trim().replace(/["]/g, ''))
                .filter(Boolean)
            : [];

        const additionalData: { [key: string]: string } = {};
        for (const key in additionalColumns) {
          if (additionalColumns[key] !== undefined) {
            const columnIndex = additionalColumns[key];
            const value = columns[columnIndex];
            console.log(`Key: ${key}, Column Index: ${columnIndex}, Value: ${value}`);
            additionalData[key] = value !== undefined && value.trim() !== '' ? value.trim() : 'N/A';
          }
        }

        console.log('additionalData', additionalData);

        if (email) {
          emails.push({ to: email, cc: cc || [], bcc: bcc || [], additionalData });
        }
      }

      setEmail((prevState: any) => ({
        ...prevState,
        to: emails.map((email) => email.to),
        cc: emails.flatMap((email) => email.cc),
        bcc: emails.flatMap((email) => email.bcc),
        additionalData: emails.map((email) => email.additionalData),
      }));
      setEmailInput('');
    };
    reader.readAsText(file);
  };

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;
  const templates = data.data || [];

  const handleOpen = () => {
    const selectedTemplateDetails = templates.find((template) => template.id === selectedTemplate);
    if (selectedTemplateDetails) {
      const imageUrl = selectedTemplateDetails.image
        ? `${getImage}email_templates/${selectedTemplateDetails.image}`
        : '';

        const formattedBody = selectedTemplateDetails.email_body
        .replace(/\n/g, '<br/>');   

        const emailBodyWithImage = imageUrl
        ? `<img src="${imageUrl}" alt="Email Template Image" /><br/>${formattedBody}`
        : formattedBody;
  

      setEmail((prevState) => ({
        ...prevState,
        subject: selectedTemplateDetails.email_subject,
        body: emailBodyWithImage,
      }));
      setEmailContent(emailBodyWithImage);
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSendEmail = async () => {
    const sendEmailFromAddress = async (fromAddress: string) => {
      const accessToken = await instance
        .acquireTokenSilent({
          scopes: ['Mail.Send'],
          account: accounts[0],
        })
        .then((response) => response.accessToken);

      const graphClient = getGraphClient(accessToken);

      for (let i = 0; i < email.to.length; i++) {
        const toRecipients = [{ emailAddress: { address: email.to[i] } }];

        const ccStartIndex = (i * email.cc.length) / email.to.length;
        const ccEndIndex = ((i + 1) * email.cc.length) / email.to.length;
        const bccStartIndex = (i * email.bcc.length) / email.to.length;
        const bccEndIndex = ((i + 1) * email.bcc.length) / email.to.length;

        const ccRecipients = email.cc.slice(ccStartIndex, ccEndIndex).map((cc) => ({
          emailAddress: { address: cc },
        }));
        const bccRecipients = email.bcc.slice(bccStartIndex, bccEndIndex).map((bcc) => ({
          emailAddress: { address: bcc },
        }));

        let personalizedContent = emailContent;
        if (email.additionalData && email.additionalData[i]) {
          for (const key in email.additionalData[i]) {
            const value = email.additionalData[i][key];
            if (value !== undefined) {
              const placeholder = `{{.${key.replace(/\s+/g, '')}}}`;
              console.log(`Replacing placeholder ${placeholder} with value ${value}`);
              personalizedContent = personalizedContent.replace(new RegExp(placeholder, 'g'), value);
            }
          }
        }

        console.log('personalizedContent', personalizedContent);

        console.log('personalizedContent', personalizedContent);

        await graphClient.api('/me/sendMail').post({
          message: {
            subject: email.subject,
            body: {
              contentType: 'HTML',
              content: personalizedContent,
            },
            from: { emailAddress: { address: fromAddress } },
            toRecipients: toRecipients,
            ccRecipients: ccRecipients,
            bccRecipients: bccRecipients,
          },
          saveToSentItems: true,
        });
      }
      handleClose();
    };

    try {
      if (fromEmails[0]) {
        await sendEmailFromAddress(fromEmails[0]);
      } else {
        console.error('No from email address provided.');
        toast.error('No from email address provided.');
      }
      console.log('Emails sent successfully from primary email!');
      toast.success('Emails sent successfully!');
    } catch (error) {
      console.error('Error sending email from primary email:', error);
      toast.error('Failed to send email from primary email.');
      try {
        await sendEmailFromAddress(secondaryEmail);
        console.log('Emails sent successfully from secondary email!');
        toast.success('Emails sent successfully!');
      } catch (secondaryError) {
        console.error('Error sending email from secondary email:', secondaryError);
        console.log('Failed to send email from both primary and secondary emails.');
        toast.error('Failed to send email from both primary and secondary emails.');
      }
    }
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> Send Email </Typography>
        </Box>
      </Box>

      <Box>
        <Box className="form-group" mb={4}>
          <InputLabel>Upload Excel file</InputLabel>
          <TextField type="file" name="file" onChange={handleUploadExcel} fullWidth />
        </Box>

        <Grid container spacing={3} className="preDefineTemplates">
          {templates.map((template) => (
            <Grid item md={4} mb={3} key={template.id}>
              <Card
                onClick={() => handleTemplateSelect(template.id)}
                sx={{
                  backgroundColor: selectedTemplate === template.id ? '#292929 !important' : '#2d2d29',
                  cursor: 'pointer',
                  boxShadow: '0px 3px 8px #5c5c5c',
                }}
                className="templateCard"
              >
                <CardContent>
                  <Typography variant="h6">{template.template_title}</Typography>
                  <Typography variant="body1">{template.email_body}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Button variant="contained" color="primary" onClick={handleOpen}>
          Send Email
        </Button>
      </Box>

      {open && (
        <div className="modal">
          <div className="modal-content">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" component="h2">
                Compose Email
              </Typography>
              <span className="close" onClick={handleClose}>
                &times;
              </span>
            </Box>

            <EmailInputWithTags label="From" emails={fromEmails} onEmailsChange={setFromEmails} />

            <EmailInputWithTags
              label="To"
              emails={email.to}
              onEmailsChange={(emails) => setEmail((prevState) => ({ ...prevState, to: emails }))}
            />

            <EmailInputWithTags
              label="CC"
              emails={email.cc}
              onEmailsChange={(emails) => setEmail((prevState) => ({ ...prevState, cc: emails }))}
            />

            <EmailInputWithTags
              label="BCC"
              emails={email.bcc}
              onEmailsChange={(emails) => setEmail((prevState) => ({ ...prevState, bcc: emails }))}
            />

            <Box className="form-group">
              <InputLabel>Email Subject</InputLabel>
              <TextField
                placeholder="Enter email subject"
                size="small"
                name="email_subject"
                value={email.subject}
                onChange={(e) => setEmail((prevState) => ({ ...prevState, subject: e.target.value }))}
                fullWidth
              />
            </Box>

            <Box className="form-group">
              <InputLabel>Body</InputLabel>
              <ReactQuill value={emailContent} onChange={setEmailContent} />
            </Box>
            <Box className="fixed-bar">
              <Button variant="contained" color="primary" onClick={handleSendEmail}>
                Send Email
              </Button>
            </Box>
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};

export default ComposeEmail;
