import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

interface OppsLandingData {
  id: number;
  user_id: number;
  page_name: string;
  created_at: string;
}

interface OppsLadningDataResponse {
  success: boolean;
  data: OppsLandingData[];
  translatableFields: string[];
}

const OppsLandingActivities = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-activity'], (ids) => ({
    url: `${fixed}admin/${locale}/user_page_activites`,
    method: 'DELETE',
    data: { ids },
  }));

  const queryClient = useQueryClient();

  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['OppsLandingPage', 'OppsLandingPageData', locale],
        });
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 40,
      renderCell: (params) => params.value,
    },

    {
      field: 'user_id',
      headerName: 'User ID',
      width: 80,
      renderCell: (params) => params.value,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      renderCell: (params) => (params.row.user ? params.row.user.email : 'N/A'),
    },
    { field: 'page_name', headerName: 'Page Name', width: 200 },
    ...getCommonColumns({
      handleDelete,
      showEdit: false,
      showYear: false,
      showCreatedAt: true,
      showDelete: true,
    }),
  ];

  const relations = ['user'];
  const relationsParam = relations.join(',');
  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<OppsLadningDataResponse>(
    ['OppsLandingPage', 'OppsLandingPageData', locale],
    {
      url: `${fixed}admin/${locale || localeFromStorage}/user_page_activites?relations=${relationsParam}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;
  console.log('check for data', data);

  const filteredData = Array.isArray(data?.data)
    ? data.data.filter((item: OppsLandingData) => item.page_name === 'Opps Page')
    : [];

  return (
    <AdminWrapper>
      <AdminPageHeader
        title="Opps Page Landing Activities"
        locale={locale}
        setLocale={setLocale}
        showLocaleButtonGroup={false}
      />
      <AdminDataGrid<OppsLadningDataResponse>
        data={{ data: filteredData }}
        columns={columns}
        setSelectedIds={setSelectedIds}
      />
    </AdminWrapper>
  );
};

export default OppsLandingActivities;
