import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';

interface AdminPageHeaderProps {
  title: string;
  locale: string;
  setLocale: (locale: string) => void;
  createLink?: string;
  enableBulkDelete?: boolean;
  showLocaleButtonGroup?: boolean;
  children?: React.ReactNode; 
}

const AdminPageHeader: React.FC<AdminPageHeaderProps> = ({
  title,
  locale,
  setLocale,
  createLink = '',
  enableBulkDelete = false,
  showLocaleButtonGroup = true,
  children,
}) => 
  (
  <Box className="pageHeader" sx={{flexWrap: 'wrap'}}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Typography variant="h6">{title}</Typography>
      {createLink && (
        <Button
          component={Link}
          to={createLink}
          sx={{ color: '#fff', lineHeight: '26px' }}
          variant="contained"
          size="small"
          color="success"
        >
          <AddIcon sx={{ fontSize: '18px' }} /> Add New
        </Button>
      )}
      {enableBulkDelete && (
        <Button sx={{ lineHeight: '26px' }} variant="contained" size="small" color="error">
          <DeleteOutlineOutlinedIcon sx={{ fontSize: '18px' }} /> Bulk Delete
        </Button>
      )}
    </Box>
    {showLocaleButtonGroup && <LocaleButtonGroup currentLocale={locale} setLocale={setLocale} />} 
    {children}
  </Box>
);

export default AdminPageHeader;