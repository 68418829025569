import { DataGrid } from '@mui/x-data-grid';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';

interface AdminDataGridProps<T> {
  data: { data?: T[] } | null;
  columns: any[]; 
  setSelectedIds: (ids: string[]) => void;
}

const AdminDataGrid = <T,>({ data, columns, setSelectedIds }: AdminDataGridProps<T>) => (
  data && data.data ? (
    <DataGrid
      rows={data.data as any[]}
      columns={columns}
      // checkboxSelection
      onRowSelectionModelChange={(newSelection) => {
        setSelectedIds(newSelection.map((id) => id.toString()) as string[]);
      }}
    />
  ) : (
    <AdminPageLoader />
  )
);

export default AdminDataGrid;