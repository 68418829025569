import React, { useState } from 'react';
import { Box, TextField, Typography, InputLabel } from '@mui/material';

interface EmailInputWithTagsProps {
  label: string;
  emails: string[];
  onEmailsChange: (emails: string[]) => void;
}

const EmailInputWithTags: React.FC<EmailInputWithTagsProps> = ({ label, emails, onEmailsChange }) => {
  const [emailInput, setEmailInput] = useState('');

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
  };

  const handleEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      const trimmedEmail = emailInput.trim();
      if (trimmedEmail && !emails.includes(trimmedEmail)) {
        onEmailsChange([...emails, trimmedEmail]);
        setEmailInput('');
      }
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    onEmailsChange(emails.filter((email) => email !== emailToRemove));
  };

  const handleEmailInputBlur = () => {
    if (emailInput.trim()) {
      const splitEmails = emailInput
        .split(/[,;\s]+/) 
        .map((email) => email.trim())
        .filter((email) => email.includes('@') && !emails.includes(email)); 
      if (splitEmails.length > 0) {
        onEmailsChange([...emails, ...splitEmails]);
        setEmailInput('');
      }
    }
  };

  return (
    <Box className="form-group">
      <InputLabel>{label}</InputLabel>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 1,
          border: '1px solid #656565',
          padding: '2px 10px',
          borderRadius: '4px',
          minHeight: '45px',
        }}
      >
        {emails.map((email, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              background: '#292929',
              padding: '0 8px',
              borderRadius: '4px',
              height: '30px',
              gap: '5px',
            }}
          >
            <Typography variant="body2">{email}</Typography>
            <span className="removeEmail" onClick={() => handleRemoveEmail(email)}>
              &times;
            </span>
          </Box>
        ))}
        <TextField
          placeholder={`Enter ${label.toLowerCase()} email address`}
          size="small"
          value={emailInput}
          onChange={handleEmailInputChange}
          onKeyDown={handleEmailKeyDown}
          onBlur={handleEmailInputBlur}
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
          }}
          sx={{ flex: 1, minWidth: '120px' }}
        />
      </Box>
    </Box>
  );
};

export default EmailInputWithTags;
