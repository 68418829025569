import { Box, Button, InputLabel } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { subMonths } from 'date-fns';
import { useContext, useState } from 'react';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';

type DateRange = [Date | null, Date | null];

const CompletedCyberTrainings = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [_selectedIds, setSelectedIds] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [triggerQuery, setTriggerQuery] = useState(false);

  const headers = [
    { label: 'Id', key: 'id' },
    { label: 'Email', key: 'email' },
    { label: 'User Name', key: 'username' },
    { label: 'Training Status', key: 'training_status' },
    { label: 'Training Start Date', key: 'training_start_date' },
    { label: 'Training End Date', key: 'training_end_date' },
  ];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 60,
      renderCell: (params) => params.value,
    },
    { field: 'email', headerName: 'Email', width: 280 },
    { field: 'username', headerName: 'User Name', width: 250 },
    { field: 'training_status', headerName: 'Training Status', width: 200 },
    { field: 'training_start_date', headerName: 'Training Start Date', width: 200 },
    { field: 'training_end_date', headerName: 'Training End Date', width: 200 },
  ];

  interface TrainingData {
    id: number;
    email: string;
    username: string;
    zone: string;
    training_status: string;
    training_start_date: string;
    training_end_date: string | null;
  }

  interface TrainingDataResponse {
    success: boolean;
    data: TrainingData;
    translatableFields: string[];
  }

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<TrainingDataResponse[]>([locale], {
    url: `${fixed}admin/${locale || localeFromStorage}/cyber_training_data`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const {
    isPending: isTraningDataPending,
    error: isTrainingDataError,
    data: isTrainingData,
  } = useAuthenticatedQuery<TrainingDataResponse>(
    ['completedTrainings', 'completedTrainingsData', dateRange],
    {
      url: `${fixed}trainings/filter-trainings`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: {
        startDate: dateRange[0],
        endDate: dateRange[1],
      },
    },
    {
      enabled: triggerQuery && dateRange[0] !== null && dateRange[1] !== null,
    },
  );

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  if (triggerQuery && isTraningDataPending) return <AdminPageLoader />;
  if (isTrainingDataError) return <div>Error: {isTrainingDataError.message}</div>;
  if (isTrainingData && !isTrainingData.success) return <AdminInternalError />;

  const filteredData = triggerQuery && isTrainingData
  ? isTrainingData.data.filter((item) => item.training_end_date !== null)
  : data.data.filter((item) => item.training_end_date !== null);
  
  return (
    <AdminWrapper>
      <AdminPageHeader
        title="List of users with completed trainings"
        locale={locale}
        setLocale={setLocale}
        showLocaleButtonGroup={false}
      >
        <Box
          className="optionalHeaderData"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', width: '27%' }}
        >
          <Box className="d-flex gap-3 align-items-center">
            <Box>
              <InputLabel id="year-label">Select Date to filter activities</InputLabel>
              <DatePicker
                selected={dateRange[0] || undefined}
                onChange={(dates) => {
                  const [start, end] = dates as [Date | null, Date | null];
                  setDateRange([start, end]);
                  if (start !== null && end !== null) {
                    setTriggerQuery(true);
                  } else {
                    setTriggerQuery(false);
                  }
                }}
                startDate={dateRange[0] || undefined}
                endDate={dateRange[1] || undefined}
                selectsRange
                monthsShown={2}
                minDate={subMonths(new Date(), 48)}
                maxDate={new Date()}
                placeholderText="Select date range"
              />
            </Box>
          </Box>
          <Button
            type="button"
            sx={{ color: '#fff', lineHeight: '26px' }}
            variant="contained"
            size="small"
            color="success"
            className="download-csv-button"
            title="Download CSV"
          >
            <CSVLink data={filteredData} headers={headers}>
              <CloudDownloadIcon sx={{ fontSize: '18px' }} /> CSV
            </CSVLink>
          </Button>
        </Box>
      </AdminPageHeader>

      <AdminDataGrid<TrainingDataResponse>
        data={{ data: Array.isArray(filteredData) ? filteredData : [filteredData] }}
        columns={columns}
        setSelectedIds={setSelectedIds}
      />
    </AdminWrapper>
  );
};

export default CompletedCyberTrainings;
