import { Box, Button, TextField, InputLabel, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedQuery, useFeedbackMutation } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';

const UpdateTrainingStatus = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [_selectedIds, setSelectedIds] = useState<string[]>([]);
  const [email, setEmail] = useState('');
  const queryClient = useQueryClient();

  const updateTrainingStatusMutation = useFeedbackMutation(['updateTrainingStatus'], (data) => ({
    url: `${fixed}training/update-training-status`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data,
  }));

  const handleUpdateStatus = async (email: string) => {
    try {
      await updateTrainingStatusMutation.mutateAsync(
        { email, status: 'Completed without saviynt' },
        {
          onSuccess: (data) => {
            console.log('check for data', data);

            if (data.success === false && data.err) {
              const errorMessage = data.err.data?.message || 'Error updating training status';
              toast.error(`Error: ${errorMessage}`);
            } else {
              setEmail('');
              toast.success('Training status updated successfully');
              queryClient.invalidateQueries(['updateTraningPage', 'updateTranningPageData', locale]);
            }
          },
          onError: (error) => {
            if (error instanceof Error) {
              toast.error(`Error updating training status: ${error.message}`);
            } else {
              toast.error('Error updating training status');
            }
          },
        },
      );
    } catch (error) {
      console.error('An error occurred while updating training status.', error);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (email) {
      handleUpdateStatus(email);
    } else {
      toast.error('Please enter email');
    }
  };

  const headers = [
    { label: 'Id', key: 'id' },
    { label: 'Email', key: 'email' },
    { label: 'User Name', key: 'username' },
    { label: 'Training Status', key: 'training_status' },
    { label: 'Training Start Date', key: 'training_start_date' },
  ];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 60,
      renderCell: (params) => params.value,
    },
    { field: 'email', headerName: 'Email', width: 280 },
    { field: 'username', headerName: 'User Name', width: 250 },
    {
      field: 'training_status',
      headerName: 'Training Status',
      width: 200,
      renderCell: (params) => (params.value ? params.value : 'Not Completed'),
    },
    { field: 'training_start_date', headerName: 'Training Start Date', width: 200 },
    {
      field: 'update_status',
      headerName: 'Update Status',
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleUpdateStatus(params.row.email)}>
          Update Status
        </Button>
      ),
    },
  ];

  interface TrainingData {
    id: number;
    email: string;
    username: string;
    zone: string;
    training_status: string;
    training_start_date: string;
    training_end_date: string | null;
  }

  interface TrainingDataResponse {
    success: boolean;
    data: TrainingData;
    translatableFields: string[];
  }

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<TrainingDataResponse[]>(
    ['updateTraningPage', 'updateTranningPageData', locale],
    {
      url: `${fixed}admin/${locale || localeFromStorage}/cyber_training_data`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  const preprocessDataForCSV = (data: TrainingData[]) => {
    return data.map((item) => ({
      ...item,
      training_status: item.training_status ? item.training_status : 'Not Completed',
    }));
  };
  
  const filteredData = data.data.filter((item) => item.training_status === null);
  const csvData = preprocessDataForCSV(filteredData);

  return (
    <AdminWrapper>
      <AdminPageHeader
        title="Update training status to completed"
        locale={locale}
        setLocale={setLocale}
        showLocaleButtonGroup={false}
      />

      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item md={6}>
            <Box className="form-group">
              <InputLabel id="email-label">Enter email to update the training Status</InputLabel>
              <TextField
                placeholder="Enter User Email"
                size="small"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Button type="submit" variant="contained" color="primary">
              Update Status
            </Button>
            <Button
              type="button"
              sx={{ color: '#fff', marginLeft: '10px' }}
              variant="contained"
              size="small"
              color="success"
              className="download-csv-button"
              title="Download CSV"
            >
              <CSVLink data={csvData} headers={headers}>
                <CloudDownloadIcon sx={{ fontSize: '18px' }} /> CSV
              </CSVLink>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <AdminDataGrid<TrainingDataResponse>
        data={{ data: filteredData }}
        columns={columns}
        setSelectedIds={setSelectedIds}
      />
    </AdminWrapper>
  );
};

export default UpdateTrainingStatus;
