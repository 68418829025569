import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';

interface CommonColumnsProps {
  handleDelete?: (ids: string[]) => void;
  showEdit?: boolean;
  showDelete?: boolean;
  showYear?: boolean;
  showCreatedAt?: boolean;
}

export function getCommonColumns({ 
  handleDelete, 
  showEdit = true, 
  showDelete = true,
  showYear = true,
  showCreatedAt = true 
}: CommonColumnsProps): GridColDef[] {
  const navigate = useNavigate();

  const columns: GridColDef[] = [];

  if (showYear) {
    columns.push({
      field: 'year',
      headerName: 'Year',
      width: 100,
      renderCell: (params) => (params.row.year ? params.row.year.year : 'N/A'),
    });
  }

  if (showCreatedAt) {
    columns.push({
      field: 'created_at',
      headerName: 'Created At',
      width: 180, 
        renderCell: (params) => {
        const date = new Date(params.value);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = (hours % 12) || 12; 
        const formattedMinutes = minutes.toString().padStart(2, '0');
        
        return `${year}-${month}-${day} ${formattedHours}:${formattedMinutes} ${ampm}`;
      },
    });
  }

  if (showEdit || showDelete) {
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      width: 160,
      renderCell: (params) => (
        <>
          {showEdit && (
            <Button
              variant="contained"
              sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
              color="secondary"
              onClick={() => {
                navigate(`edit/${params.row.id}`);
              }}
            >
              <EditIcon sx={{ fontSize: '18px' }} />
            </Button>
          )}
          {showDelete && handleDelete && (
            <Button
              variant="contained"
              sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
              onClick={() => handleDelete([params.row.id])}
              color="error"
            >
              <DeleteOutlineIcon sx={{ fontSize: '18px' }} />
            </Button>
          )}
        </>
      ),
    });
  }

  return columns;
}